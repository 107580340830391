<template>
  <div>
    <el-menu mode="horizontal" default-active="1">
      <el-menu-item index="1">我的通讯录</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},

  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='less' scoped>
</style>